/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import '/src/@core/scss/base/bootstrap-extended/variables';

.delete-icon {  
	border-radius: 4px;
	padding: 1px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	margin-top: 0px;
		transition: all .35s ease-in-out;
	
		&:hover {
			opacity: .8;
			transition: all .25s ease-in-out;
		}
}
 
.edit-icon {
	background: #7367f0;
	color: #fff;
	border-radius: 4px;
	padding: 1px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	margin-top: 0px;
	transition: all .35s ease-in-out;

	&:hover {
		opacity: .8;
		transition: all .25s ease-in-out;
	}
}

.edit-icon svg {
	stroke: #fff !important;
	margin: 0 auto;
}

.add-more {
	padding: 10px 9px !important;
	font-weight: 600;
}

.reduce-btn button {
	margin: 0px !important;
}

.reduce-btn {
	height: 15px !important;
}
.error-area {
	height: 16px;
}

.error-area .error.error {
	line-height: 16px;
	font-size: 13px;
}

.move-div {
	height: 20px;

	.cursor-move {
		margin-top: -4px;
	}
}
.review-card {
	.card {
		background: #70C0D1;
		color: #ffffff;
		text-align: center;
		padding: 15px;
		box-shadow: 0px 0px 20px #a9a9a9ed !important;

		&.purple-bg {
			background-color: #AC7CE8;
		}

		&.green-bg {
			background-color: #6BC18E;
		}

		&.yellow-bg {
			background-color: #DEB21A;
		}

		&.red-bg {
			background-color: #F48C8C;
		}

		h2 {
			font-size: 52px;
			color: #ffffff;
			font-weight: 600;
		}

		h4 {
			font-size: 20px;
			font-weight: 600;
			color: #ffffff;
		}
	}
}

.progess-data {
	padding-top: 0px;
	padding-left: 1px;
}

.rating-start {
	svg {
		width: 18px;
	}
}
.max-files-reached {
	pointer-events: none;
	cursor: default;
}
.ql-container {
	border-bottom-left-radius: 0.357rem;
	border-bottom-right-radius: 0.357rem;
}

.ql-toolbar {
	border-top-left-radius: 0.357rem;
	border-top-right-radius: 0.357rem;
}

.add-more-review {
	width: auto;
		position: absolute;
		left: 0;
		// margin-top: 5px;
}

.card-review {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.details-area {
	width: calc(100% - 40px);
}
.ql-editor {
	background: #fff !important;
}

.error-field-show {
	span {
		color: red;
	}
}
.text-container-ellipsis {
	width: 180px;
	/* Adjust this width as needed */
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.text-container-ellipsis-approvalGroup {
	width: 190px;
	/* Adjust this width as needed */
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.viewFiles {
	background: #00cfe8 !important;
	text-align: center;
	padding: 0.786rem 1.5rem;
	border-radius: 0.358rem;
}

.questionAnswer {  
	.questionFromCheck {
		flex: 1;
			text-align: center;
			// margin-top: 5px;
	}
}

.cursor-edit-disabled {
	cursor: not-allowed;
	stroke: #000 !important;

}
.radio-align {
	width: 100%;
	max-width: 114px;
}
.css-t3ipsp-control:focus-within {
	box-shadow: none !important;
	border-color: $primary !important;
}

.css-13cymwt-control:hover {
	border-color: #d8d6de !important;
}
@media only screen and (max-width: 1565px) {
	.mangerHeading {
		font-size: 18px !important;
	}
}

@media only screen and (max-width: 1473px) {
	.mangerHeading {
		font-size: 16px !important;
	}
}

@media only screen and (max-width: 1381px) {
	.mangerHeading {
		font-size: 15px !important;
	}
}

@media only screen and (max-width: 1334px) {
	.mangerHeading {
		font-size: 14px !important;
	}
}