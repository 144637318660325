.w-300 {
  width: 300px;
}
.ReviewAreaHeading {
  font-size: 12px;
  color: black;
  font-weight: bold;
}

.backgroupColorofCard {
  background: #fff;
  margin-bottom: 12px;
}
.landing-page {
  padding: 30px 40px;

  .heading {
    font-size: 19px;
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 3px solid $primary;
    margin-bottom: 20px;
  }

    .heading1 {
      font-size: 19px;
      color: black;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 10px;
    }
  .blocks {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 24px;
  }

  .icon {
    margin-top: 25px;
    width: 117px;
    height: 126px;
  }

  .block {
    border-radius: 20px 20px 0 0;
    border: 1px solid #dcdcdc;
    border-top: 3px solid $primary;
    text-align: center;
    padding-top: 10px;
    width: 200px;
    height: 200px;
    color: black;
    font-weight: bold;
  }
}