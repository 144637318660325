@import "./base/bootstrap.scss";
@import "./base/bootstrap-extended.scss";
@import "./base/colors.scss";
@import "./base/components.scss";

// Themes
@import "./base/themes/dark-layout.scss";
@import "./base/themes/bordered-layout.scss";
@import "./base/themes/semi-dark-layout.scss";
@import "./react/pages/landing-page.scss";
// @import './base/custom-rtl.scss';

// React Specific
@import "react/index";

body .react-confirm-alert-overlay{
  background: rgba(255, 255, 255, 0.5) !important;
  .react-confirm-alert-button-group{
     button:first-child{
       background: $success;
     }
     button:last-child{
      background: $danger;
     }
  }
}
.container-img {
  position: relative;
  width: 110px;
  height: 110px;
  margin-bottom: 20px;
}

.img-fluid {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  height: 110px;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container-img:hover {
  background-color: #000;
  cursor: pointer;
}

// .container-img:hover .img-fluid {
//   background-color: #000;
//   cursor: pointer;
// }

.container-img:hover .img-fluid {
  opacity: 0.2;
}

.container-img:hover .middle {
  opacity: 1;
}

.none {
  display: none;
}

.text {
  background-color: transparent;
  color: white;
  font-size: 16px;
  width: 50px;
  height: 20px;
}
.lauchpad-text1 {
  padding: 0 10px;
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

 